import React, { useContext, useEffect, useState } from 'react';
import StateContext from '../providers/state';
import { navigate } from "@reach/router"
import { withTranslation } from 'react-i18next';
import PageContainer from '../containers/PageContainer';
import { Row, Col } from 'react-bootstrap';


const Conditions = ({ storeId, t, i18n }) => {
  const { state, setImeiInfo, setPartConditions, setErrorMessage } = useContext(StateContext);
  const CDN = "https://cdn.greenpanda.io"
  const { imeiInfo, imei } = state;
  const [parts, setParts] = useState('');
  const [disableButton, setdisableButton] = useState(true);
  const errorjson = require('../errorCodes.json')
  const [selectedConditions, setSelectedConditions] = useState([]);
  
  const cosmeticsMapping = {
    "no_light_scratches": 0.75,
    "deep_scratches": 0.5,
    "cracks": 0.25,
    "yes": 1.0,
    "no": 0.0,
    "titanium": "titanium",
    "stainless_steel": "stainless_steel",
    "aluminum": "aluminum"
  }

  const category = sessionStorage.getItem('category');
  useEffect(() => {
    fetch(`https://imei-service.greenpanda.io/v1/parts/definitions?category=${category}`
    ).then(response => response.json())
      .then(data => {
        if (data.error || data.parts === []) {
          setErrorMessage(errorjson['genericError'])
          console.log('Error fetching parts')
          navigate(`/${storeId}/error`);
        } else {
          setParts(data.parts);
        }
      })
      .catch(e => {
        console.log('eskase', e)
        // setLoading(false);
        // setError(errorText);
      })
  }, []);

  const handleBtn = (condition, data) => {
    let updatedValue = {code: condition, condition: data};
    let newState = [...selectedConditions];
    let found = false;
    let index
    for(let key in newState){
      if(newState[key].code === condition){
        found = true;
        index = key;
      }
    }
    if(found){
      newState[index] = updatedValue;
      setSelectedConditions(newState);
    }else{
      setSelectedConditions(prevState => [...prevState, updatedValue]);
    }
  };

  useEffect(() => {
    setPartConditions(selectedConditions);
  },[selectedConditions])


  const handleSubmit = async () => {
    let partner = localStorage.getItem('partner');
    if(partner==='greenpanda'){
      partner = 'pandas';
    }
   
    let arr = [];
    let deviceAssessmentData = {};
    const mathRandom = Math.random().toString(36).slice(2);
    
    for(let key in selectedConditions){
      let part = selectedConditions[key].code.split('_');
      
      for(var i = 1 ; i < part.length ; i++){
        part[i] = part[i].charAt(0).toUpperCase() + part[i].substr(1);
        part.join();
      }   
      let joinedArr = part.join('');
      
      deviceAssessmentData[joinedArr] = cosmeticsMapping[selectedConditions[key].condition];
      arr.push({code: selectedConditions[key]['code'], condition: selectedConditions[key].condition});
    }
    
    deviceAssessmentData['brand'] = imeiInfo?.brand;
    deviceAssessmentData['model'] = imeiInfo?.modelName;
    deviceAssessmentData['manufacturer'] = imeiInfo?.manufacturer;
    deviceAssessmentData['category'] = "smartwatch";
    
    deviceAssessmentData['flow'] = "pandas";
    deviceAssessmentData['imei'] = imei;
    deviceAssessmentData['sessionId'] = mathRandom;

    console.log('deviceassessmentdata', deviceAssessmentData)
    

    const asessmentResponse = await fetch('https://imei-service.greenpanda.io/device-assessments', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(deviceAssessmentData),
    });

    const offerData = {};
    offerData['deviceType'] = "smartwatch";
    offerData['partner'] = partner;
    offerData['imei'] = imei;
    offerData['currencies'] = 'EUR';
    offerData['parts'] = 'yes';
    console.log('offerdata', offerData)

    const offerResponse = await fetch(`https://imei-service.greenpanda.io/v2/offer/`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(offerData)
      }).then(response => response.json())
        .then(data => {
          console.log(data);   
          
          setImeiInfo(data)
          navigate(`/${storeId}/offer`)
    })
}

useEffect(() => {
  let arr = []
  for(let key in parts){
    let obj = {code: parts[key].code, condition: ''}
    arr.push(obj);
  }
  setSelectedConditions(arr);
}, [parts])

useEffect(()=> {
  let hasEmptyValue = false;
  for(let part in selectedConditions){
    if(selectedConditions[part].condition===''){
      hasEmptyValue = true;
      break;
    }
  }
  if(hasEmptyValue){
    setdisableButton(true);
  }else{
    setdisableButton(false);
  }
}, [selectedConditions])


  return (
    <PageContainer hasExit>
        <Row>
          <Col xs='12'>
            <div className='text-center mb-5'>
                <div><h4 id="offerModel">{imeiInfo.brand} {imeiInfo.model}</h4></div>
                <p>last checks to calculate a final offer</p>
            </div>
          </Col>
          <Col xs='12'>
            <div>
            {parts && parts.map((part, index) => {
                return(
                  <div key={index} className='d-flex justify-content-between align-items-center m-auto' style={{borderTop: '#E6E6E6 solid 1px', padding: '20px 0', maxWidth: '850px'}}>
                    <div className='d-flex align-items-center'>
                      <img style={{marginRight: '10px'}} src={CDN + `/tradein-app/${part.code}.svg`} alt="condition" width="30" height="30"/>
                      <p className='m-0'>{t(`conditions.${part.code}`)}</p>
                    </div>
                    
                    <div className='d-flex'>
                      {part.conditions.map((condition) => {
                        return(
                          condition!=='default' && <div onClick={() => handleBtn(part.code, condition)} key={condition} className='partCondition'>
                            {selectedConditions[index]?.condition === condition ?
                                <p className='m-0' style={{color: '#41d900', cursor: 'pointer', fontWeight: '600'}}>{t(`conditions.${condition}`)}</p>
                                :
                                <p style={{cursor: 'pointer'}} className='m-0'>{t(`conditions.${condition}`)}</p>
                            }
                          </div>
                        )
                      })}
                    </div>
                  </div>

                )
            })}
            </div>
          </Col>
          <div className='mx-auto pt-5'>
            <button onClick={() => handleSubmit()} style={{border: '1px solid #41d900', borderRadius: '3px', backgroundColor: '#41d900', color: '#ffffff', padding: '20px 60px', boxShadow: '0 0 14px -1px rgb(0 0 0 / 17%)', fontSize: '24px', marginTop: '50px'}} type='submit' className={disableButton ? 'disabled' : ''} disabled={disableButton}>Επιβεβαίωση</button>
          </div>
      </Row>
    </PageContainer>
  );
};

export default withTranslation()(Conditions);
