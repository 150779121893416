import React, {useState, useCallback, useEffect} from 'react';
import {Form, Alert, Spinner, Button} from 'react-bootstrap';
import { navigate } from "@reach/router"
import PageContainer from '../containers/PageContainer';

const Login = (props) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [input, setInput] = useState(localStorage.getItem('storeId'));
    const [storeData, setStoreData] = useState();
  

    useEffect(() => {
      const storeId = localStorage.getItem('storeId');
      console.log(storeId)
      if (storeId) navigate(`/${storeId}`);
    }, []);
  
    const handleUsernameSubmit = useCallback((_storeId = undefined) => {
        setLoading(true);
        console.log("hi")
        const param = _storeId && typeof(_storeId) === 'string' ? _storeId : input;
        fetch(`https://server.greenpanda.io/scripts/getUser.php?user=${param}`)
          .then(response => response.json())
          .then(json => {
            // console.log(json);
            if(!json) {
              setLoading(false);
              setInput('')
              setError('The username is wrong. Please try again');
              return;
            }
            setLoading(false);
            setStoreData(json)
            localStorage.setItem('storeId', input);
            localStorage.setItem('store', json.store);
            localStorage.setItem('partner', json.partner);
            localStorage.setItem('voucherOnly', json.voucherOnly);
            if(input.toLowerCase() === 'wind1') {
              navigate(`/voucher`);
            } else {
              navigate(`/${input}`);
            }

          })
          .catch(error => {
            setLoading(false);
            setError('The username is wrong. Please try again');
            // console.log('error', error)
          })
      }, [setLoading, input, setStoreData, setInput])
    
    return (
      <PageContainer noExit>
        <Form onSubmit={(e)=>{e.preventDefault();handleUsernameSubmit();}}>
          
          <h5>Type your store username</h5>

          <Form.Group controlId="formBasicCheckbox">
            <Form.Control
              type="text"
              name="storeId"
              value={input || ""}
              disabled={loading}
              onChange={e => setInput(e.currentTarget.value)}
              size="large"
              style={{fontSize: "25px", textAlign: "center"}}
            />
          </Form.Group>
          { !!error && 
                <Alert variant={'danger'}>
                  {error}
                </Alert>
          }
          <Button
            className="primaryButton button"
            size="small"
            type="submit" 
            disabled={loading}
            onClick={handleUsernameSubmit}
          >
          {loading && <Spinner animation="border" className="mr-3" />}
          <span>Login</span></Button>

        </Form>
      </PageContainer>);
}

export default Login;