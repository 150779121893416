import React from 'react';
import styled from 'styled-components';
import {Modal} from 'antd';
import {useLocation} from '@reach/router';
import Countdown from './countdown';

// import './modalStyles.scss'


const TimerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`
const _Modal = ({visible, onOk, storeId, title, text}) => {
  const {pathname} = useLocation();

  return (
    <Modal
      visible={visible && pathname !== `/${storeId}`}
      title={title}
      onOk={onOk}
      // onCancel={onCancel}
      okText="Yes"
      // cancelText={null}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <TimerContainer>
        <Countdown />
      </TimerContainer>
      <h3 className="text-center">{title}</h3>
      <h4 className="text-center">{text}</h4>
    </Modal>
  );
};

export default _Modal;
