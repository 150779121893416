import {withTranslation} from 'react-i18next';
import React, {useState, useContext, useRef, useEffect} from 'react';
import styled from 'styled-components';
import StateContext from '../providers/state';
import { navigate } from '@reach/router';
import { Button, Modal, Alert} from 'react-bootstrap';
import { Spinner} from 'react-bootstrap';
import {Title} from "../components/typography";
import SignatureCanvas from 'react-signature-canvas'
import PageContainer from "../containers/PageContainer";
import Barcode from "react-barcode";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import PrivacyPolicy from './PrivacyPolicy';
import PrivacyPolicySamsung from './PrivacyPolicySamsung';

const OfferPill = styled.div`
  border-radius: 80px 80px;
  padding: 0 10px 0 20px;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00E500;
  height: 42px;
  /* width: 330px; */
  margin-bottom: 30px;
`;

const Price = styled.div`
  border: solid 4px #3AC93A;
  border-radius: 80px 80px;
  padding: 0 10px;
  margin-left: 30px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
`;

const Anchor = styled.a`
  text-decoration: none;
  color: green;
  &:hover {
    cursor: pointer;
	text-decoration:none;
	color: #00E500;
  }
`;

const ClearButton = styled.button`
//   border: solid 4px #D6DFE6;
  font-weight: bold;
//   border-radius: 30px 30px;
//   padding: 15px 25px;
  background-color: white;
  font-size: 15px;
  color: #263846;
  &:hover {
    cursor: pointer;
  }
  margin-bottom: -28px;
  z-index: 100;
`;



const Sign = ({storeId, t, i18n}) => {
    const {state, setField} = useContext(StateContext);
    const {imei, imeiInfo, offer} = state;
    const signatureEl = useRef(null);
    const [signtureError, setSigntureError] = useState(false);
    const [hasSigned, setHasSigned] = useState(false);
	const queryString=window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const store = urlParams.get('store')? urlParams.get('store'):localStorage.getItem('store');
	const uuid = store + "-" + uuidv4();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [terms1, onTermsChange] = useState(false);
	const [terms2, onTermsChange2] = useState(false);
  const [paidClicked, setPaidClicked] = useState(false);
  const webhookUrl = 'https://hook.eu1.make.com/a2tpqwm1e0xh11l2z6l6fiusjp31ltjd';
  let imageUrl;
    if (state.imeiInfo.brand === 'apple') {
        imageUrl = `https://server.greenpanda.io/images/devices/${state.imeiInfo.brand.toLowerCase()}/${state.imeiInfo.model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    }else{
        imageUrl = `https://cdn.greenpanda.io/devices/${state.imeiInfo.brand.toLowerCase()}/${state.imeiInfo.model.replaceAll(' ', '_').toLowerCase()}.png`
    }

	const saveBarcode = () => {
		var s = new XMLSerializer().serializeToString(document.getElementsByTagName("svg")[1]);
		var res = "data:image/svg+xml;base64,"+window.btoa(s);
		console.log(res)
		return res;
  };

	useEffect(()=> {
		setField('signature', false);
		setField('barcode', saveBarcode());
	}, []);

  function changeToEng(c) {
    if (c === 'very_good') return 'Like New';
    if (c === 'good') return 'Good';
    if (c === 'poor') return 'Acceptable';
    if (c === 'faulty') return 'Faulty';
  }

  function changeToGreek(c) {
      if (c === 'very_good') return 'Σαν Καινούρια';
      if (c === 'good') return 'Καλή';
      if (c === 'poor') return 'Μέτρια';
      if (c === 'faulty') return 'Κακή';
  }

    const onGetPaidClick = () => {
    setPaidClicked(true);
		console.log("CLICKED!")
      if (hasSigned) {
        setField('signature', signatureEl.current.getTrimmedCanvas().toDataURL('image/png'));
		setLoading(true)

		setError(null)

			var postObj = {
				uuid,
				data : {
					...state,
					storeId,
					signature: signatureEl.current.getTrimmedCanvas().toDataURL('image/png')
				},
				brand: state.imeiInfo.brand,
				model: state.imeiInfo.model,
				store,
				imei: state.imei,
				state: 'tx',
				evaluation: 1,
				identification: 1,
				offer: 1,
				tx: 1,
				price: state.offer,
				coupon: imeiInfo.coupon? imeiInfo.coupon: (state.promo && state.promo.price? state.promo.price : 0),
				updated: moment().format("DD-MM-YYYY hh:mm:ss"),
				method: state.paymentMethod ?? "voucher"
			}

      const webhookData = {
        email: state.offerForm.email,
        first_name: state.offerForm.name.split(' ')[0],
        last_name: state.offerForm.name.split(' ')[1],
        phone_number: state.offerForm.tel,
        date: new Date().toISOString().slice(0, 10),
        partner: localStorage.getItem('partner'),
        device_category: state.deviceType,
        brand: state.imeiInfo.brand,
        model: state.imeiInfo.model,
        capacity: state.imeiInfo.capacity,
        condition_gr: changeToGreek(state.condition),
        condition: changeToEng(state.condition),
        buy_price: state.offer,
        buy_coupon: imeiInfo.coupon? imeiInfo.coupon: (state.promo && state.promo.price? state.promo.price : 0),
        trade_in_store: store,
        trade_in_payment_method: state.paymentMethod ?? "voucher"
      }
      
      fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(webhookData)
      }).catch((error)=>{
        console.log(error);
      })

			fetch(`https://session-service.greenpanda.io/tradein`, {
			  method: 'POST', 
			  cache: 'no-cache', 
			  headers: { 'Content-Type': 'application/json' },
			  referrerPolicy: 'no-referrer', 
			  body: JSON.stringify(postObj) 
			})
			.then(response => response.json())
			.then(data => {
				if(data.isValid) {
					setLoading(false)
					console.log(data)
					navigate(`/thankyou`);
				} else {
					setLoading(false)
					setError("Please try again...")
				}
			}).catch((e)=>{

				setLoading(false)
				setError("Please try again...")

			});
      }
    };

    const [show, setShow] = useState(false);
	const [show2,setShow2] =useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
	const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

  return (
    <>
    
    <PageContainer >
        <OfferPill>
          {t('sign.sale')} {imeiInfo.name} <Price>{offer}€</Price>
        </OfferPill>
        <StyledTitle>
			{t('sign.title')}
        </StyledTitle>
				<div className='terms' onClick={() => onTermsChange(!terms1)}>
                    {!terms1 && <img src="/checkbox_up.svg" alt="checkbox" width="20" style={{marginRight:"10px"}}/>}
                    {terms1 && <img src={"/checkbox_down.svg"} alt="checkbox" width="20" style={{marginRight:"10px"}}/>}
					<Anchor onClick={handleShow}>{t('sign.terms')}</Anchor>
            	</div>
				<div className='terms' onClick={() => onTermsChange2(!terms2)}>
                    {!terms2 && <img src="/checkbox_up.svg" alt="checkbox" width="20" style={{marginRight:"10px"}}/>}
                    {terms2 && <img src={"/checkbox_down.svg"} alt="checkbox" width="20" style={{marginRight:"10px"}}/>}
					<Anchor onClick={handleShow2}>{t('sign.terms2')}</Anchor>
            	</div>
        <ClearButton
          disabled={!hasSigned}
          onClick={() => {
            signatureEl && signatureEl.current.clear();
            setHasSigned(false)
          }}
        >Clear</ClearButton>
        <SignatureCanvas
          penColor="#00E500"
          backgroundColor="#ECEFF2"
          canvasProps={{width: 500, height: 300, className: `mb-4 sigCanvas ${signtureError ? 'sigError' : ''}`}}
          ref={signatureEl}
          onBegin={() => setSigntureError(null)}
          onEnd={() => setHasSigned(true)}
        />
        <Button
          disabled={!terms1 || !terms2 || !hasSigned || loading || paidClicked}
          className="primaryButton button"
          type="submit" 
          onClick={onGetPaidClick}
        >
		{loading && <Spinner animation="border" className="mr-3" />}
          {t('sign.cta')}
        </Button>

		{ !!error && 
                <Alert variant={'warning'}>
                  {error}
                </Alert>
          }
		<div className="d-none">
		<Barcode value={imei} height={40}/>
		</div>
    </PageContainer>


    <Modal show={show} onHide={handleClose} className="my-modal">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
			{store != '0153' && <PrivacyPolicy/>}
      {store == '0153' && <PrivacyPolicySamsung/>}
			</Modal.Body>
        <Modal.Footer>
          <Button 
          className="primaryButton " onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

	  <Modal show={show2} onHide={handleClose2} className="my-modal">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
		<div>
			<center><p>
				<strong> Υπεύθυνη Δήλωση </strong> <br/>
				<strong> (άρθρο 8 Ν.1599/1986) </strong>
			</p></center>
			<p>Η ακρίβεια των στοιχείων που υποβάλλονται με αυτή τη δήλωση μπορεί να ελεγχθεί με βάση το αρχείο άλλων υπηρεσιών (άρθρο 8 παρ. 4 Ν. 1599/1986).</p>
			<p><strong>ΠΡΟΣ:</strong> Πάσα Αρμόδια Αρχή</p>
			<p>Με ατομική μου ευθύνη και γνωρίζοντας τις κυρώσεις, που προβλέπονται από τις διατάξεις της παρ. 6 του άρθρου 22 του Ν. 1599/1986, ότι δηλαδή «[ό]ποιος εν γνώσει του δηλώνει ψευδή γεγονότα ή αρνείται ή αποκρύπτει τα αληθινά με έγγραφη υπεύθυνη δήλωση του άρθρου 8 τιμωρείται με φυλάκιση τουλάχιστον τριών μηνών. Εάν ο υπαίτιος αυτών των πράξεων σκόπευε να προσπορίσει στον εαυτόν του ή σε άλλον περιουσιακό όφελος βλάπτοντας τρίτον ή σκόπευε να βλάψει άλλον, τιμωρείται με κάθειρξη μέχρι 10 ετών», δηλώνω ότι:</p>
			<p>Είμαι αδιαμφισβήτητος νόμιμος κύριος της συσκευής κινητού τηλεφώνου που πρόκειται να πουλήσω μέσω της υπηρεσίας "Green Panda trade-in  App", η οποία περιήλθε στην κατοχή μου νομίμως δι’ αγοράς και παράδοσης της νομής και κατοχής της από εξουσιοδοτημένο πάροχο, κατάστημα κινητής τηλεφωνίας ή οποιονδήποτε άλλο φορέα διενεργεί νομίμως αγοραπωλησίες συσκευών κινητής τηλεφωνίας και διαθέτει όλες τις σχετικές προς τούτο άδειες.</p>
			<p>Η εν λόγω συσκευή κινητής τηλεφωνίας δεν συνιστά προϊόν κλοπής, υπεξαίρεσης, κλεπταποδοχής ή διάθεσης προϊόντων εγκλήματος, ληστείας ή οποιουδήποτε άλλου ποινικού αδικήματος ή παράνομης πράξης.</p>
		</div>
		</Modal.Body>
        <Modal.Footer>
          <Button 
          className="primaryButton " onClick={handleClose2}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
};

export default withTranslation()(Sign);
