import {Button} from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  min-width: 300px;
  height: 100px;
  font-size: 25px;
  font-weight: 600;
  background: #263846;
  border-radius: 25px;
  color: #04E502;
  border: 0px;
`;

export default StyledButton;
