import React, { useContext, useCallback, useEffect} from 'react';
import styled from 'styled-components';
import StateContext from '../providers/state';
import {navigate} from "@reach/router"
import {withTranslation} from 'react-i18next';

import {Row} from 'react-bootstrap';

import PageContainer from "../containers/PageContainer";




// const OfferPill = styled.div`
//   border-radius: 80px 80px;
//   border: solid 8px #3AC93A;
//   padding: 0 30px;
//   text-align: center;
//   color: white;
//   background-color: #00E500;
//   font-size: 49px;
//   font-weight: bold;
//   text-align: center;
//   /* display: flex;
//   justify-content: center;
//   align-items: center; */
//   margin-left: 133px;

//   .euro {
//     font-size: 35px;
//   }
// `;



const OfferPill = styled.div`
  border-radius: 80px 80px;
  padding: 0 10px 0 20px;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00E500;
  height: 42px;
  /* width: 330px; */
`;

const Price = styled.div`
  border: solid 4px #3AC93A;
  border-radius: 80px 80px;
  padding: 0 10px;
  margin-left: 30px;
`;



const PaymentMethod = ({storeId, t, i18n}) => {
  const {state, setPaymentMethod} = useContext(StateContext);
  const {offer} = state;
  const {condition} = state;
  const phone = state.imeiInfo;
  console.log(state)
  // useEffect(() => {
  //   if (!offer) {
  //     console.log('NAVIGATING 1')
  //     navigate(`/${storeId}?q=e`);
  //     return;
  //   }
  // }, [offer]);

  const onMethodSelect = useCallback((method) => {
    setPaymentMethod(method);
    const queryString=window.location.search;
    navigate(`/${storeId}/form/${queryString}`);
  }, [storeId, setPaymentMethod]);

  // if (!offer) {
  //   console.log('NAVIGATING 12')

  //   return <Redirect noThrow to={`/${storeId}?q=r`}/>;
  // }

  return (
    <PageContainer>
            <OfferPill>
              {phone.name} <Price>{offer}€</Price>
            </OfferPill>
          <br/>
          <br/>
          <h3 className="mb-4 title text-center">{t('paymentMethods.title')}</h3>

          <br/>
          {/* <h3 id="offerPrice2">{offer || 120}<span className="">€</span></h3> */}


          <Row id="methods" className="row">
            <div  onClick={() => onMethodSelect('iban')} className="secondaryButton button">
                 <h1>🏦 IBAN</h1>
            </div>
            <div  onClick={() => onMethodSelect('card')} className="secondaryButton button">
                <h1>💳 Debit Card</h1>
            </div>
          </Row>
    </PageContainer>
  );
};

export default withTranslation()(PaymentMethod);
