import React, {useState, useCallback} from 'react';
import {navigate} from "@reach/router"
import {withTranslation} from 'react-i18next';
import PageContainer from '../containers/PageContainer';
import {Form, Alert, Button, Spinner, Card, Badge} from 'react-bootstrap';
import Barcode from "react-barcode";


const Voucher = ({t, i18n}) => {
  const [voucherCode, setVoucherCode] = useState('');
  const [voucher, setVoucher] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);


  const onUseVoucher = useCallback(() => {
    if (!voucherCode || typeof(voucherCode) !== 'string') {
      return;
    }
    setError(null)
    setLoading(true);
    fetch(`https://session-service.greenpanda.io/useVoucher`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({voucherCode: voucherCode})
    }).then(response => response.json())
      .then(voucherCodeInfo => {
        if(voucherCodeInfo.error || voucherCodeInfo.length===0) {
          setError('Voucher not found')
          setLoading(false);

        } else {
          onVoucherSend();
        }
      }).catch((e)=> {
        console.log(e)
        setError('Something went wrong')
        setLoading(false);

      });
  }, [voucherCode]);

  const onVoucherSend = useCallback(() => {
    if (!voucherCode || typeof(voucherCode) !== 'string') {
      return;
    }
    setVoucher(null)
    setError(null)
    setLoading(true);
    fetch(`https://session-service.greenpanda.io/voucherCode`, {
      // fetch(`http://localhost:8080/voucherCode`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({voucherCode: voucherCode})
    }).then(response => response.json())
      .then(voucherCodeInfo => {
        if(voucherCodeInfo.error || voucherCodeInfo.length===0) {
          setError('Voucher not found')
          setLoading(false);

        } else {
          setVoucher(voucherCodeInfo);
          setLoading(false);
          saveBarcode();
        }
      }).catch((e)=> {
        console.log(e)
        setError('Voucher not found')
        setLoading(false);

      });
  }, [voucherCode]);


  const onChangeInput = event => {
    const input = event.target.value;
    if(input.length<=15) {
      console.log(input)
      setVoucherCode(input);
      setError(null)
      setVoucher(null)
    }
  };


  const saveBarcode = () => {
    var s = new XMLSerializer().serializeToString(document.getElementsByTagName("svg")[1]);
    var res = "data:image/svg+xml;base64,"+window.btoa(s);
    console.log(res)
    return res;
  };
  return (
    <PageContainer progress="100" noExit voucher>


          <Form onSubmit={(e)=>{e.preventDefault();}}>
        
            <h4 className="mb-4 text-center" onClick={() => {navigator.clipboard.writeText('357296092404683')}}>{t('voucher.description')}</h4>


            <Form.Group controlId="formBasicCheckbox">
                <Form.Control
                  type="text"
                  name="imei"
                  value={voucherCode || ""}
                  disabled={loading}
                  onChange={onChangeInput}
                  maxLength="22"
                  size="large"
                  style={{fontSize: "25px", textAlign: "center", minWidth: "400px"}}
                />
              </Form.Group>
              { !!error &&  <Alert variant={'danger'}> {error} </Alert> }
              <Button
                className="primaryButton button"
                size="small"
                type="submit" 
                disabled={loading || !voucherCode}
                onClick={onVoucherSend}
              >
                  {loading && <Spinner animation="border" className="mr-3" />}
                  <span>{t('voucher.cta')}
                  </span></Button>
          </Form>

          {voucher && <>
            <Card style={{ padding: '25px' }}>
              <Card.Body>
                <Card.Title>
                  <h2>
                  {voucher.amount} € 
                  { voucher.isValid && <Badge variant="success" className="float-right">{voucher.message}</Badge> }
                  { voucher.message==='Expired' && <Badge variant="danger" className="float-right">{voucher.message}</Badge> }
                  { voucher.message==='Already used' && <Badge variant="warning" className="float-right">{voucher.message}</Badge> }
                  </h2>
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{voucher.message}</Card.Subtitle>
                <Card.Text>
                  
                    <li>Code: {voucher.code}</li>
                    <li>Date: {voucher.start}</li>
                    {/* <li>End: {voucher.end}</li> */}
                    <hr/>
                    <li>Brand: {voucher.brand}</li>
                    <li>Model: {voucher.model}</li>
                    <hr/>
                    {JSON.parse(voucher.data).offerForm  && <>
                      <li>Name: {JSON.parse(voucher.data).offerForm.name}</li>
                      <li>Email: {JSON.parse(voucher.data).offerForm.email}</li>
                      <li>Phone: {JSON.parse(voucher.data).offerForm.tel}</li>
                      </>
                    }
                    {!JSON.parse(voucher.data).offerForm  && <>
                      <li>Name: {JSON.parse(voucher.data).bankAccount.name}</li>
                      <li>Email: {JSON.parse(voucher.data).bankAccount.email}</li>
                      <li>Phone: {JSON.parse(voucher.data).bankAccount.mobile}</li>
                      </>
                    }
                    

		              <Barcode id="barcode" value={voucher.code} height="40"/>
                </Card.Text>
                {voucher.isValid && 
                  <Card.Link href="#">
                      <Button
                        className="primaryButton btn-block"
                        size="small"
                        type="submit" 
                        disabled={loading || !voucherCode}
                        onClick={onUseVoucher}
                      >USE</Button>
                  </Card.Link>
                }
              </Card.Body>
            </Card>

          </>}
    </PageContainer>
  );
}

export default withTranslation()(Voucher);
