import React from 'react'

const noop = () => {};

const StateContext = React.createContext({
  state: {imei: null, questions: [], answers: {}, language: 'el', setImeiInfo: noop, deviceType:'smartphone'},
  setState: () => {}
});

export default StateContext;
