import React, { useContext, useEffect, useState } from 'react';
import StateContext from '../providers/state';
import { navigate, Redirect } from "@reach/router"
import { withTranslation } from 'react-i18next';
import PageContainer from '../containers/PageContainer';
import { Row, Col, Button, Spinner, Alert, Form } from 'react-bootstrap';
import { Radio, Space } from 'antd';
import Mobile from '../assets/mobile.png';
import Broken from '../assets/broken.png';


const Offer = ({ storeId, t, i18n }) => {
  const errorjson = require('../errorCodes.json')
  const { state, setOfferCondition, setPaymentMethod, setOfferCoupon, setErrorMessage, setPromo, setImeiInfo, setBrands } = useContext(StateContext);
  const partner = localStorage.getItem('partner');
  const [error, setError] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promoAmount, setPromoAmount] = useState();
  const [alreadyUsed, setAlreadyUsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disableText, setDisableText] = useState(false);
  const [valid, setValid] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const { questions: { questions }, answers, imeiInfo } = state;
  const condition = imeiInfo.condition;
  const [offer, setOffer] = useState(imeiInfo.localPrices ? imeiInfo.localPrices[0].price : imeiInfo.price);
  const [couponApplied, setCouponApplied] = useState(false)
  const coupon = imeiInfo.localCoupons ? imeiInfo.localCoupons[0].price : imeiInfo.coupon;
  const promo = imeiInfo.localPromo ? imeiInfo.localPromo[0].price : imeiInfo.promo;
  const errorText = t('home.error');
  const [samsungPromo, setSamsung] = useState(false);
  const [xiaomiPromo, setXiaomi] = useState(false);
  const [youPromo, setYou] = useState(false);
  const [pandasPromo, setPandas] = useState(false);
  const selectedConditions = state.partConditions;
  const category = sessionStorage.getItem('category');
  const CDN = "https://cdn.greenpanda.io"

  useEffect(() => {
    setOfferCondition(offer + coupon + promo, condition);
  }, [offer]);
  useEffect(() => {
    if (offer === null || offer === undefined) {
      navigate(`/${storeId}`)
    }
  }, [offer])
  const onOfferChange = (e) => {
    if (e.target.value == offer + coupon) {
      setOfferCoupon(e.target.value, coupon);
    } else {
      setOfferCoupon(e.target.value, null);
    }
  }
  useEffect(() => {
    if (state.clicked) {
      if (imeiInfo && (imeiInfo.fmiOn || imeiInfo.fmiON)) {
        setErrorMessage(errorjson['Find my iPhone is on'])
        navigate(`/${storeId}/error`);
      }
      if (localStorage.getItem('voucherOnly') == 1) {
        setPaymentMethod('voucher')
        navigate(`/${storeId}/form`, { state });
      } else {
        navigate(`/${storeId}/paymentMethods`);
      }
    }
  }, [state.clicked]);

  useEffect(() => {
    if (state.createImei){
      imeiInfo.brand = 'Apple';
    }
    
    if (state.createImei) {
      fetch(`https://server.greenpanda.io/scripts/getBrands.php`)
        .then(response => response.json())
        .then(data => {
          data.push({brand:"Other"});
          setBrands(data)
        }).catch(e => {
          console.log('eskase', e)
          setLoading(false);
          setError(errorText);
        })
    } else if (!offer || offer === 0) {
      setErrorMessage(errorjson['Price not found'])
      navigate(`/${storeId}/error`);
    }
  }, [])

  const onYouClick = () => {
    setPaymentMethod('voucher');
    fetch(`https://imei-service.greenpanda.io/offer`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ imei: state.imei, deviceType: state.deviceType, currencies: 'EUR', partner: 'yougr', promoCode: promoCode })
        }).then(response => response.json())
          .then(data => {
            setYou(true);
            setFields(data);
            (storeId === 'gpscooter1') ?
              navigate(`/${storeId}/form/?store=0083`, { state }) :
              navigate(`/${storeId}/form/?store=0086`, { state });
          })
  }
  const onSamsungClick = () => {
      navigate(`/${storeId}/paymentMethods/?store=0153`, { state });
  }

  const onXiaomiClick = () => {
      navigate(`/${storeId}/paymentMethods/?store=0156`, { state });
  }
  const onAnswerClick = () => {
    if (state.offer) {
      if (imeiInfo && (imeiInfo.fmiOn || imeiInfo.fmiON)) {
        setErrorMessage(errorjson['Find my iPhone is on'])
        navigate(`/${storeId}/error`);
      }
      if (localStorage.getItem('voucherOnly') == 1) {
        setPaymentMethod('voucher')
        navigate(`/${storeId}/form`, { state });
      } else {
        navigate(`/${storeId}/paymentMethods`);
      }
    }
  }

  const checkEnterKey = (e) => {
    if (e.key === 'Enter' && promoCode !== '') {
      e.preventDefault();
      handleSubmit();
    }
  }

  const handleInput = (e) => {
    if (!couponApplied) {
      setPromoCode(e)
      if (e === "") {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }

  }

  const setFields = (deviceInfo) => {
    setImeiInfo(deviceInfo)
    setDisabled(true);
    setDisableText(true);
    setValid(true);
    setInvalid(false);
    setOffer(deviceInfo.price + deviceInfo.promo)
    setCouponApplied(true);
    setPromoAmount(deviceInfo.promo);
    if (promoCode !== "")
      setPromo(
        promoCode,
        deviceInfo.promo,
        deviceInfo.price + deviceInfo.promo
      );
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!couponApplied) {
      let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      if (state.createImei){
        let couponPartner;
        if(state.imei.charAt()==='S'){
          couponPartner = 'samsunggr';
        }else if(state.imei.charAt() === 'X'){
          couponPartner = 'xiaomigr';
        }
        fetch(`https://imei-service.greenpanda.io/promos?code=${promoCode}&partner=${couponPartner}`)
          .then(response => response.json())
          .then(data => {
            if (data.result === 'success') {
              if(couponPartner==='samsunggr'){
                setSamsung(true);
              }else if(couponPartner==='xiaomigr'){
                setXiaomi(true);
              }
              let deviceInfo = state.imeiInfo;
              deviceInfo.promo = data.amount;
              deviceInfo.localPromo[0].price = data.amount;
              setFields(deviceInfo);
            } else {
              if (data.result === 'unknown_promo') {
                setInvalid(true);
                setValid(false);
              } else if (data.result === 'promo_already_used') {
                setAlreadyUsed(true);
              } else {
                setError(errorjson['genericError'])
              }
            }
          }).catch(e => {
            console.log('eskase', e)
            setLoading(false);
            setError(errorText);
          })
      } else {
      let promises = [fetch(`https://imei-service.greenpanda.io/offer`, {
        method: 'post',
        headers: headers,
        body: JSON.stringify({ imei: state.imei, deviceType: state.deviceType, currencies: 'EUR', partner: partner, promoCode: promoCode })
      })];
      if (storeId.includes('scooter') || storeId.includes('courier')){
        promises.push(fetch(`https://imei-service.greenpanda.io/offer`, {
          method: 'post',
          headers: headers,
          body: JSON.stringify({ imei: state.imei, deviceType: state.deviceType, currencies: 'EUR', partner: 'yougr', promoCode: promoCode })
        }),fetch(`https://imei-service.greenpanda.io/offer`, {
          method: 'post',
          headers: headers,
          body: JSON.stringify({ imei: state.imei, deviceType: state.deviceType, currencies: 'EUR', partner: 'samsunggr', promoCode: promoCode })
        }),fetch(`https://imei-service.greenpanda.io/offer`, {
          method: 'post',
          headers: headers,
          body: JSON.stringify({ imei: state.imei, deviceType: state.deviceType, currencies: 'EUR', partner: 'xiaomigr', promoCode: promoCode })
        }))
      }
      Promise.all(promises).then((responses) =>{
        return Promise.all(responses.map( response => {
          return response.json();
      }));
    }).then( data => {
      let pandas,you,samsung,xiaomi
      if (storeId.includes('scooter') || storeId.includes('courier')){
        [pandas, you, samsung, xiaomi] = data;
      } else {
        pandas = data[0];
        you ={"error": 'unknown_promo'};
        samsung = {"error": 'unknown_promo'}
        xiaomi = {"error": 'unknown_promo'};
      }
      setLoading(false);
      if (pandas.error === 'unknown_promo' && you.error === 'unknown_promo' && samsung.error === 'unknown_promo' && xiaomi.error === 'unknown_promo'){
          setInvalid(true);
          setValid(false);
      } else if (pandas.error === 'promo_already_used' || you.error === 'promo_already_used' || samsung.error === 'promo_already_used' || xiaomi.error==='promo_already_used'){
        setAlreadyUsed(true);
      } else if ( pandas.error && you.error && samsung.error && xiaomi.error){
        for (let i=0; i<data.length; i++){
          if (data[i].error != 'unknown_promo'){
            setError(errorjson[data[i].error])
          }
        }
      } else {
        let deviceInfo;
        if (samsung.promo){
          deviceInfo = samsung;
          setSamsung(true);
        } else if (you.promo){
          deviceInfo = you;
          setYou(true);
        } else if(pandas.promo){
          deviceInfo = pandas;
          setPandas(true);
        }else {
          deviceInfo = xiaomi;
          setXiaomi(true);
        }
        setFields(deviceInfo);
      }
      }).catch(e => {
        console.log('eskase', e)
        setLoading(false);
        setError(errorText);
      })
      }
    }
  }

  return (
    <PageContainer hasExit>
      <Row className="pt-5">
        <Col xs="12" md="12" className="text-center mb-5">
          <h4 id="offerModel">{imeiInfo.brand} {imeiInfo.model}</h4>
        </Col>
      </Row>
      {state.createImei && <>
        <Row style={{marginTop: '-30px', marginBottom: '30px'}}>
          <Col xs="12" md="12" className="text-center">
            <h4><b>IMEI:</b> {state.imei}</h4>
          <Form>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Row className='align-items-center'>
              <Col xs="2" className='text-right p-0'><Form.Label className='m-0'><b>Brand</b></Form.Label></Col>
              <Col xs="10" className='pr-0'><Form.Control as="select" custom onChange={(e) => { let imeiInfo = state.imeiInfo; imeiInfo.brand = e.target.value; setImeiInfo(imeiInfo) }}>
                {state.brands.map((brand, index) => {
                  return <option key={index} value={brand.brand}>{brand.brand}</option>
                })}
              </Form.Control></Col>
              </Row>
            </Form.Group>
          </Form>
          </Col>
        </Row>
        <Col xs="12" className="text-center mb-5">
          <img src={Broken} style={{ height: "200px" }} alt="Phone" />
        </Col>
      </>
      }
      <Row>
        {!state.createImei && <>
          <Col xs="6" className="text-right mb-5">
          {category !== 'smartwatch'  &&
            <>
              {condition !== "faulty" && <img src={Mobile} style={{ height: "200px" }} alt="Phone" />}
              {condition === "faulty" && <img src={Broken} style={{ height: "200px" }} alt="Phone" />}
            </>
          }
          {category === 'smartwatch'  &&
            <img src={CDN + `/tradein-app/good-watch.svg`} alt="smartwatch"/>
          }
          </Col>
          <Col xs="6" className="text-left mb-5">
            {category !== 'smartwatch' ?
              <h5 id="offerCondition" className="mb-2">{t('offer.condition')}:</h5>
              :
              <h5 id="offerCondition" className="mb-2">{t('offer.smartwatch')}:</h5>
            }
            {category !== 'smartwatch' &&
              <h5 id="offerConditionPill" className={`${condition} mb-3`}><div id="offerConditionRuby" className={condition}></div>{t('offer.' + condition)}</h5>
            }
            {category === 'smartwatch' &&
              <ul>
                {selectedConditions.map((condition) => {
                  return(
                    <>
                    <li>{t(`offer.${condition.code}.${condition.condition}`)}</li>
                    </>
                  )
                })}
              </ul>
            }
            {imeiInfo.partsInfo && category !== 'smartwatch' ?
              imeiInfo.partsInfo.battery.condition && imeiInfo.condition!=='faulty' &&
              <h6 className="mb-2">Battery Class: {imeiInfo.partsInfo.battery.condition}</h6>
              :
              <></>
            }
            {condition === "faulty" &&
              <>
                <h6 className="mb-2">Failed tests:</h6>
                {imeiInfo.phonecheck.Tests.Failed.map((test, i) =>
                  <li key={i}>{test}</li>
                )
                }
              </>
            }
          </Col>
        </>}
        <div className='m-auto pb-4'>
          <Col xs="12" className='couponContainer p-0'>
            <input className='couponInput' disabled={disableText} type="text" placeholder="Enter promo code..." onChange={e => handleInput(e.target.value)} onKeyPress={(e) => { checkEnterKey(e) }} />
            <input className='couponButton' type="submit" onClick={handleSubmit} disabled={disabled} value="Apply Promo" />
          </Col>
          {!!error && <Alert variant={'danger'}> {error} </Alert>}
          <Col xs="12">
            {!valid && !invalid && !alreadyUsed ?
              <div className='fillSpace'>
              </div>
              :
              <div>
                <p hidden={!valid} className='successMessage'>{promoAmount}€ promo code</p>
                <p hidden={!invalid} className='errorMessage'>invalid promo code</p>
                <p hidden={!alreadyUsed} className='errorMessage'>promo already used</p>
              </div>
            }
          </Col>
        </div>

        {!state.createImei && !samsungPromo && !youPromo && coupon > 0 &&
          <Col xs="12" md="12" className="text-center">
            <Button onClick={onOfferChange} value={state.offer} disabled={loading} style={{ fontSize: "23px" }} className="mt-5 primaryButton button">
              {loading && <Spinner animation="border" className="mr-3" />}
              {t('offer.cta1')} {state.offer}€ {t('offer.cta2')}
            </Button>
          </Col>
        }
        <br />
        {!state.createImei && !samsungPromo && !youPromo && !xiaomiPromo && (!coupon || coupon === 0) &&
          <Col xs="12" md="12" className="text-center">
            <Button onClick={onAnswerClick} disabled={loading} style={{ fontSize: "23px" }} className="mt-5 primaryButton button">
              {loading && <Spinner animation="border" className="mr-3" />}
              {t('offer.cta1')} {state.offer}€ {t('offer.cta2')}
            </Button>
          </Col>
        }
        {!state.createImei && !samsungPromo && !pandasPromo && !xiaomiPromo && (storeId.toLowerCase().includes('scooter') || storeId.toLowerCase().includes('courier') )&&
          <Col xs="12" md="12" className="text-center mt-4">
            <Button onClick={onYouClick} style={{ fontSize: "23px" }} className="mt-5 yougrButton button">{t('offer.yougr')}</Button>
          </Col>
        }
        {samsungPromo && (storeId.toLowerCase().includes('scooter') || storeId.toLowerCase().includes('courier'))&&
          <Col xs="12" md="12" className="text-center mt-4">
            <Button onClick={onSamsungClick} style={{ fontSize: "23px" }} className="mt-5 samsungButton button">{t('offer.samsung')} - {state.offer}€</Button>
          </Col>
        }
        {xiaomiPromo && (storeId.toLowerCase().includes('scooter') || storeId.toLowerCase().includes('courier')) &&
          <Col xs="12" md="12" className="text-center mt-4">
            <Button onClick={onXiaomiClick} style={{ fontSize: "23px" }} className="mt-5 xiaomiButton button">{t('offer.xiaomi')} - {state.offer}€</Button>
          </Col>
        }
      </Row>
    </PageContainer>
  );
};

export default withTranslation()(Offer);
