import Logo from '../assets/logo.png';
import { Link } from "@reach/router"
import {withTranslation} from 'react-i18next';
import { Select } from 'antd';
import {Row, Col, Button} from 'react-bootstrap';

const PageContainer = ({children, noExit, progress, voucher=false, i18n}) => (
    <div id="pageContainer">
      <div id="progressBar" style={{'width': progress+'%' }}></div>
      <div id="whiteContainer" className="container">
        <div id="topActions" className="row">
            <Col>
              <a href={"/"+localStorage.getItem('storeId')}><img src={Logo} id="logo" alt="Green Panda Logo"/></a>
            </Col>
            <Col className="text-center">
              <Select defaultValue={i18n.language} onChange={(val) => i18n.changeLanguage(val)}>
                <Select.Option value='el'>Ελληνικά 🇬🇷</Select.Option>
                <Select.Option value='en'>English 🇺🇸</Select.Option>
              </Select>
            </Col>
            <Col className="text-right">
              {<Link to={`/logout`} id="logout"><Button variant="light">Logout</Button></Link>}
              {!noExit && <Link to={`/thankyou`} id="exit">X</Link>}
              {voucher && <Link to={`/voucher`} id=""><Button variant="light">voucher 🔎</Button></Link>}
            </Col>
        </div>
        <div id="whiteBody">
          {children}
        </div>

      </div>
    </div>
);
export default withTranslation()(PageContainer);