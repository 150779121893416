import {useEffect, useContext, useState} from 'react';
import {navigate} from "@reach/router"
import StateContext from '../providers/state';
import PageContainer from '../containers/PageContainer';
import {withTranslation} from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {Link} from "@reach/router"
import {Result} from 'antd';
import moment from "moment";

const REDIRECT_INTERVAL = 10000;

const ThankYou = ({t, i18n}) => {
  const {state, resetState} = useContext(StateContext);
  const [time, setTime] = useState(REDIRECT_INTERVAL/1000);
  const startedTime = Math.floor(Date.now()/1000);
  const storeId = localStorage.getItem('storeId');
  const store = localStorage.getItem('store');
  const uuid = store + "-" + uuidv4();
  const goHome = ()=> {
    resetState();
    window.location = `/${storeId}`;
  };
  
  useEffect(() => {

    if (!storeId) {
      navigate('/login');
      return;
    }
    console.log("IS tx?", state.signature)
    if(!state.signature) {
      var postObj = {
        uuid,
        data : {
          ...state,
          storeId
        },
        brand: state.imeiInfo.brand,
        model: state.imeiInfo.model,
        store,
        imei: state.imei,
        state: 'offer',
        evaluation: 1,
        identification: 1,
        offer: 1,
        tx: 0,
        price: state.offer,
        coupon: state.imeiInfo.coupon? state.imeiInfo.coupon: 0,
        updated: moment().format("DD-MM-YYYY hh:mm:ss"),
        method: state.paymentMethod
      }
      fetch(`https://session-service.greenpanda.io/tradein`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(postObj) 
      });
      console.log('posting object:', postObj);
    }

    console.log('THANK YOU')


    let timeout = setTimeout(() => {
      goHome();
    }, REDIRECT_INTERVAL);


    let intval = setInterval(() => {
      setTime(time - (Math.floor(Date.now()/1000) - startedTime))
    }, 1000);

    return () => {
      clearInterval(intval);
      clearTimeout(timeout);
    }
  }, []);

  
  return (
    <PageContainer noExit>
         <h1>{t('thankyou.title')}</h1>
         <Result
          status="success"
          title={t('thankyou.text') + " " + time}
          extra={
            <Link to={"/"+storeId} onClick={goHome}  className="primaryButton button">{t('thankyou.button')}</Link>
          }
        />
    </PageContainer>
  )
}


export default withTranslation()(ThankYou);